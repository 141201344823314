import { Packet } from "../Packet";
export class S39ManufacturingWorkstation extends Packet {
    constructor(action) {
        super();
        this.action = action;
    }
    getPacketID() {
        return 39;
    }
}
(function (S39ManufacturingWorkstation) {
    let Action;
    (function (Action) {
        Action[Action["COMPONENT_ITEM_PROCESS_1Y_NX_CUTTING1D_DONE"] = 1] = "COMPONENT_ITEM_PROCESS_1Y_NX_CUTTING1D_DONE";
        Action[Action["COMPONENT_ITEM_PROCESS_1Y_1X_DONE"] = 2] = "COMPONENT_ITEM_PROCESS_1Y_1X_DONE";
        Action[Action["COMPONENT_ITEM_PROCESS_1X_1X_DONE"] = 3] = "COMPONENT_ITEM_PROCESS_1X_1X_DONE";
        Action[Action["COMPONENT_ITEM_PROCESS_NY_1X_DONE"] = 4] = "COMPONENT_ITEM_PROCESS_NY_1X_DONE";
        Action[Action["COMPONENT_ITEM_PROCESS_1X_SORT_1X_DONE"] = 5] = "COMPONENT_ITEM_PROCESS_1X_SORT_1X_DONE";
        Action[Action["ADD_PLATFORM_TO_WORKSTATION"] = 10] = "ADD_PLATFORM_TO_WORKSTATION";
        Action[Action["PLATFORM_FINISH"] = 11] = "PLATFORM_FINISH";
        Action[Action["PLATFORM_ADD_ITEM_SUGGESTIONS"] = 12] = "PLATFORM_ADD_ITEM_SUGGESTIONS";
        Action[Action["PLATFORM_ADD_ITEM"] = 13] = "PLATFORM_ADD_ITEM";
        Action[Action["GET_PLATFORM_EDIT"] = 14] = "GET_PLATFORM_EDIT";
        Action[Action["SET_ITEM_PLATFORM"] = 15] = "SET_ITEM_PLATFORM";
        Action[Action["SET_ITEM_QUANTITY"] = 16] = "SET_ITEM_QUANTITY";
        Action[Action["SET_ITEM_PROCESS_LIST"] = 17] = "SET_ITEM_PROCESS_LIST";
        Action[Action["SET_ITEM_METADATA"] = 18] = "SET_ITEM_METADATA";
        Action[Action["GET_MANUFACTURING_OPERATORS"] = 19] = "GET_MANUFACTURING_OPERATORS";
        Action[Action["MULTISPINDLE_SETUP_TUNE"] = 20] = "MULTISPINDLE_SETUP_TUNE";
        Action[Action["MULTISPINDLE_SETUP_TUNING_SAVE"] = 21] = "MULTISPINDLE_SETUP_TUNING_SAVE";
        Action[Action["SET_MANUFACTURING_WORKSTATION_PROCESS_SETUP_KEY"] = 22] = "SET_MANUFACTURING_WORKSTATION_PROCESS_SETUP_KEY";
        Action[Action["GET_WORKSTATION_PROCESS_MAP"] = 23] = "GET_WORKSTATION_PROCESS_MAP";
        Action[Action["ADD_PROCESS_REFER"] = 24] = "ADD_PROCESS_REFER";
        Action[Action["SET_PLATFORM_HIDDEN"] = 25] = "SET_PLATFORM_HIDDEN";
        Action[Action["SEND_CNC_DRILL_TASK"] = 26] = "SEND_CNC_DRILL_TASK";
        Action[Action["GET_ALL_VISIBLE"] = 27] = "GET_ALL_VISIBLE";
        Action[Action["SET_ITEM_STATUS"] = 28] = "SET_ITEM_STATUS";
        Action[Action["FIND_ITEM_ON_PLATFORM"] = 29] = "FIND_ITEM_ON_PLATFORM";
        Action[Action["PROCESS_REVERT"] = 30] = "PROCESS_REVERT";
        Action[Action["GET_AVAILABLE_TO_REVERT"] = 31] = "GET_AVAILABLE_TO_REVERT";
    })(Action = S39ManufacturingWorkstation.Action || (S39ManufacturingWorkstation.Action = {}));
    ;
})(S39ManufacturingWorkstation || (S39ManufacturingWorkstation = {}));
